import React, { lazy } from 'react';
import Loadable from '~/layouts/Loadable';

const FullLayout = Loadable(
  lazy(() => import('~/layouts/fullLayout/FullLayout'))
);
const StudioLayout = Loadable(
  lazy(() => import('~/layouts/studioLayout/StudioLayout'))
);
const StudioLayoutNoSidebar = Loadable(
  lazy(() => import('~/layouts/studioLayout/StudioLayoutNoSidebar'))
);

const Information = Loadable(
  lazy(() => import('~/pages/information/Information'))
);

const Companyinfo = Loadable(
  lazy(() => import('~/pages/information/InfoCompany'))
);
const Statistics = Loadable(
  lazy(() => import('~/pages/statistics/Statistics'))
);
const GameAdminMain = Loadable(
  lazy(() => import('~/pages/launchtools/studioadmin/GameAdminMain'))
);
const Companies = Loadable(
  lazy(() => import('~/pages/launchtools/companies/Companies'))
);
const CreateCompany = Loadable(
  lazy(() => import('~/pages/launchtools/companies/CreateCompany'))
);
const GameAdmin = Loadable(
  lazy(() => import('~/pages/launchtools/studioadmin/GameAdmin'))
);
const Game = Loadable(
  lazy(() => import('~/pages/launchtools/studioadmin/Game'))
);
const LaunchTools = Loadable(
  lazy(() => import('~/pages/launchtools/LaunchToolsMain'))
);
const WhiteListNFT = Loadable(
  lazy(() => import('~/pages/launchtools/whitelistNFT/WhiteListNFT'))
);
const MintNewWhiteListNFT = Loadable(
  lazy(() => import('~/pages/launchtools/whitelistNFT/MintNewWhiteListNFT'))
);
const SubMechanismCN = Loadable(
  lazy(() => import('~/pages/launchtools/subMechanism/SubscriptionMechanismCN'))
);
const SubMechanismCreate = Loadable(
  lazy(
    () => import('~/pages/launchtools/subMechanism/SubscriptionMechanismCreate')
  )
);

const MysteryBoxCN = Loadable(
  lazy(() => import('~/pages/launchtools/mysteryboxCN'))
);
const MysteryBoxListCN = Loadable(
  lazy(() => import('~/pages/launchtools/mysteryboxCN/MysteryBoxListCN'))
);
const MysteryBoxCreateCN = Loadable(
  lazy(() => import('~/pages/launchtools/mysteryboxCN/MysteryBoxCreateCN'))
);

const Collection = Loadable(
  lazy(() => import('~/pages/launchtools/collection/Collection'))
);
const CollectionList = Loadable(
  lazy(() => import('~/pages/launchtools/collection/CollectionList'))
);
const CollectionCreate = Loadable(
  lazy(() => import('~/pages/launchtools/collection/CollectionCreate'))
);

const DynamicPricing = Loadable(
  lazy(() => import('~/pages/launchtools/dynamicpricing/DynamicPricing'))
);
const Market = Loadable(lazy(() => import('~/pages/market/Market')));

const FeaturedInfo = Loadable(
  lazy(() => import('~/pages/featured/FeaturedInformation'))
);
const FeaturedList = Loadable(
  lazy(() => import('~/pages/featured/FeaturedList'))
);
const Featured = Loadable(lazy(() => import('~/pages/featured/Featured')));

const Airdrop = Loadable(
  lazy(() => import('~/pages/launchtools/airdrop/Airdrop'))
);

const AirdropCreate = Loadable(
  lazy(() => import('~/pages/launchtools/airdrop/AirdropCreate'))
);

const AirdropList = Loadable(
  lazy(() => import('~/pages/launchtools/airdrop/AirdropList'))
);

const ProjectList = Loadable(
  lazy(() => import('~/pages/launchtools/project/ProjectList'))
);

const ProjectCreate = Loadable(
  lazy(() => import('~/pages/launchtools/project/ProjectCreate'))
);
const QuickMint = Loadable(
  lazy(() => import('~/pages/launchtools/quickmint/QuickMint'))
);

/* ****Routes***** */
const Router = () => [
  {
    path: '/',
    element: <StudioLayoutNoSidebar />,
    children: [{ path: '/', element: <GameAdminMain /> }],
  },
  {
    path: '/',
    element: <StudioLayout />,
    children: [
      { path: '/information', element: <Information /> },
      { path: '/information/:gameId', element: <Information /> },
      { path: '/featured', element: <FeaturedList /> },
      { path: '/featured/:id', element: <Featured /> },
      { path: '/featuredinfo', element: <FeaturedInfo /> },
      { path: '/featuredinfo/:id', element: <FeaturedInfo /> },
      { path: '/companyinfo', element: <Companyinfo /> },
      { path: '/statistics/:gameId', element: <Statistics /> },
      { path: '/statistics/:gameId', element: <Statistics /> },
      { path: '/games', element: <GameAdmin /> },
      { path: '/games/:gameId', element: <Game /> },
      { path: '/gameadminmain/create', element: <GameAdmin /> },

      { path: '/launchtools/:gameId', element: <LaunchTools /> },

      // whitelist
      { path: '/whitelist', element: <WhiteListNFT /> },
      { path: '/whitelist/:gameId', element: <WhiteListNFT /> },
      {
        path: '/whitelist/:gameId/create',
        element: <MintNewWhiteListNFT />,
      },
      { path: '/whitelist/:gameId/:id', element: <MintNewWhiteListNFT /> },

      // subscription
      { path: '/subscription', element: <SubMechanismCN /> },
      { path: '/subscription/:gameId', element: <SubMechanismCN /> },
      { path: '/subscription/:gameId/create', element: <SubMechanismCreate /> },
      { path: '/subscription/:gameId/:id', element: <SubMechanismCreate /> },

      // mysterybox
      { path: '/mysterybox', element: <MysteryBoxCN /> },
      { path: '/mysterybox/:gameId', element: <MysteryBoxListCN /> },
      { path: '/mysterybox/:gameId/create', element: <MysteryBoxCreateCN /> },
      { path: '/mysterybox/:gameId/:id', element: <MysteryBoxCreateCN /> },

      // collection
      { path: '/collection', element: <Collection /> },
      { path: '/collection/:gameId', element: <CollectionList /> },
      { path: '/collection/:gameId/create', element: <CollectionCreate /> },
      { path: '/collection/:gameId/:id', element: <CollectionCreate /> },

      //  airdrop
      { path: '/airdrop', element: <Airdrop /> },
      { path: '/airdrop/:gameId', element: <AirdropList /> },
      { path: '/airdrop/:gameId/create', element: <AirdropCreate /> },
      { path: '/airdrop/:gameId/:id', element: <AirdropCreate /> },

      // project
      { path: '/project/:gameId', element: <ProjectList /> },
      { path: '/project/:gameId/create', element: <ProjectCreate /> },
      { path: '/project/:gameId/:id', element: <ProjectCreate /> },

      // dynamic pricing
      { path: '/dynamicpricing', element: <DynamicPricing /> },
      { path: '/dynamicpricing/:gameId', element: <DynamicPricing /> },
      // market
      { path: '/market', element: <Market /> },
      // quick mint
      { path: '/quickmint', element: <QuickMint /> },
    ],
  },
  {
    path: '/',
    element: <StudioLayoutNoSidebar />,
    children: [{ path: '/gameadminmain', element: <GameAdminMain /> }],
  },
  {
    path: '/',
    element: <StudioLayoutNoSidebar />,
    children: [
      { path: '/companies', element: <Companies /> },
      { path: '/companies/create', element: <CreateCompany /> },
    ],
  },
];

export default Router;
